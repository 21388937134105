import { Box } from '@chakra-ui/react';

import TradesToBeMade from './Components/TradesToBeMade';
import DailyData from './Components/DailyData';
import TradesMade from './Components/TradesMade';
import ChessHolding from './Components/ChessHolding';

interface TradesProps {
  activeSubTab: 'tradesToBeMade' | 'dailyData' | 'tradesMade' | 'chessHolding';
}

const Trades = ({ activeSubTab }: TradesProps) => {
  return (
    <Box>
      {activeSubTab === 'tradesToBeMade' && <TradesToBeMade />}
      {activeSubTab === 'dailyData' && <DailyData />}
      {activeSubTab === 'tradesMade' && <TradesMade />}
      {activeSubTab === 'chessHolding' && <ChessHolding />}
    </Box>
  );
};

export default Trades;
