import { Box, Button, Checkbox, Grid, HStack } from '@chakra-ui/react';

interface DisplayedFieldProps {
  displayedColumns: { [key: string]: boolean };
  onColumnChange: (column: string, isChecked: boolean) => void;
  onSave: (columns: { [key: string]: boolean }) => void;
  onShowAll: () => void;
  onHideAll: () => void;
}

const DisplayedField = ({
  displayedColumns,
  onColumnChange,
  onSave,
  onShowAll,
  onHideAll,
}: DisplayedFieldProps) => {
  const handleSave = () => {
    onSave(displayedColumns);
  };

  return (
    <Box padding="2" borderWidth="1px" borderRadius="md" borderColor="gray.300" maxWidth="1180px">
      {/* <Grid templateColumns="repeat(6, 1fr)" gap={1}>
        {Object.keys(displayedColumns).map((key) => (
          <Checkbox
            key={key}
            isChecked={displayedColumns[key]}
            onChange={(e) => onColumnChange(key, e.target.checked)}
          >
            {key
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, (str) => str.toUpperCase())
              .trim()}
          </Checkbox>
        ))}
      </Grid> */}
      <Grid templateColumns="repeat(6, 1fr)" gap={1}>
        <Checkbox
          isChecked={displayedColumns.saleId}
          onChange={(e) => onColumnChange('saleId', e.target.checked)}
        >
          Sale ID
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.termsAndConditions}
          onChange={(e) => onColumnChange('termsAndConditions', e.target.checked)}
        >
          T&Cs
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.kyc}
          onChange={(e) => onColumnChange('kyc', e.target.checked)}
        >
          KYC?
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.srnVerified}
          onChange={(e) => onColumnChange('srnVerified', e.target.checked)}
        >
          SRN?
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.saleDate}
          onChange={(e) => onColumnChange('saleDate', e.target.checked)}
        >
          Sale Date
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.shareholderName}
          onChange={(e) => onColumnChange('shareholderName', e.target.checked)}
        >
          Shareholder Name
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.brokerage}
          onChange={(e) => onColumnChange('brokerage', e.target.checked)}
        >
          Brokerage
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.postcode}
          onChange={(e) => onColumnChange('postcode', e.target.checked)}
        >
          Postcode
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.companyOrCode}
          onChange={(e) => onColumnChange('companyOrCode', e.target.checked)}
        >
          Company or Code
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.srn}
          onChange={(e) => onColumnChange('srn', e.target.checked)}
        >
          SRN
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.units}
          onChange={(e) => onColumnChange('units', e.target.checked)}
        >
          Units
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.contactName}
          onChange={(e) => onColumnChange('contactName', e.target.checked)}
        >
          Contact Name
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.contactEmail}
          onChange={(e) => onColumnChange('contactEmail', e.target.checked)}
        >
          Contact Email
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.poa}
          onChange={(e) => onColumnChange('poa', e.target.checked)}
        >
          PoA?
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.settlementFromSale}
          onChange={(e) => onColumnChange('settlementFromSale', e.target.checked)}
        >
          Settlement (from Sale)
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.trust}
          onChange={(e) => onColumnChange('trust', e.target.checked)}
        >
          Trust?
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.bsbNumber}
          onChange={(e) => onColumnChange('bsbNumber', e.target.checked)}
        >
          BSB Num.
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.accountNumber}
          onChange={(e) => onColumnChange('accountNumber', e.target.checked)}
        >
          Acc. Number
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.bankAccountName}
          onChange={(e) => onColumnChange('bankAccountName', e.target.checked)}
        >
          Bank Account Name
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.registeredAddress}
          onChange={(e) => onColumnChange('registeredAddress', e.target.checked)}
        >
          Reg. Address
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.tPlus0}
          onChange={(e) => onColumnChange('tPlus0', e.target.checked)}
        >
          T+0?
        </Checkbox>
        <Checkbox
          isChecked={displayedColumns.sale}
          onChange={(e) => onColumnChange('sale', e.target.checked)}
        >
          Sale
        </Checkbox>
      </Grid>

      <Box textAlign="right" marginTop={4}>
        <HStack marginTop={4} justifyContent="space-between">
          <HStack>
            <Button variant="outline" colorScheme="gray" size="sm" onClick={onShowAll}>
              Show All
            </Button>
            <Button variant="outline" colorScheme="gray" size="sm" onClick={onHideAll}>
              Hide All
            </Button>
          </HStack>

          <Button colorScheme="teal" size="sm" onClick={handleSave}>
            Save
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default DisplayedField;
