import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, HStack } from '@chakra-ui/react';
import CustomDatePicker from '../../../Common/CustomDatePicker';

import Pagination from '../../../Common/Pagination';
import DisplayedField from './Components/DisplayedField';

import { tradesToBeMadeApi } from '../../../../api/api';
import { GetTradesToBeMade200ResponseInner } from '../../../../api/trades-api/generated';

const TradesToBeMade = () => {
  // const tradesData = [
  //   {
  //     saleId: 'abc123',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'Yes',
  //     srnVerified: 'Yes',
  //     saleDate: '30/11/2024',
  //     shareholderName: 'Omer',
  //     brokerage: 'Brokerage1',
  //     postcode: '2000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123123',
  //     units: 50000,
  //     contactName: 'Contact1',
  //     contactEmail: 'contact1@example.com',
  //     poa: 'Yes',
  //     settlementFromSale: 'Settlement1',
  //     trust: 'Yes',
  //     bsbNumber: '123-456',
  //     accountNumber: '987654321',
  //     bankAccountName: 'Bank Account 1',
  //     registeredAddress: 'Address 1',
  //     tPlus0: 'Yes',
  //     sale: 'Sale1',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Tairan',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Massimo',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Run',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Yuvi',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc123',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'Yes',
  //     srnVerified: 'Yes',
  //     saleDate: '30/11/2024',
  //     shareholderName: 'Omer',
  //     brokerage: 'Brokerage1',
  //     postcode: '2000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123123',
  //     units: 50000,
  //     contactName: 'Contact1',
  //     contactEmail: 'contact1@example.com',
  //     poa: 'Yes',
  //     settlementFromSale: 'Settlement1',
  //     trust: 'Yes',
  //     bsbNumber: '123-456',
  //     accountNumber: '987654321',
  //     bankAccountName: 'Bank Account 1',
  //     registeredAddress: 'Address 1',
  //     tPlus0: 'Yes',
  //     sale: 'Sale1',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Tairan',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Massimo',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Run',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Yuvi',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc123',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'Yes',
  //     srnVerified: 'Yes',
  //     saleDate: '30/11/2024',
  //     shareholderName: 'Omer',
  //     brokerage: 'Brokerage1',
  //     postcode: '2000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123123',
  //     units: 50000,
  //     contactName: 'Contact1',
  //     contactEmail: 'contact1@example.com',
  //     poa: 'Yes',
  //     settlementFromSale: 'Settlement1',
  //     trust: 'Yes',
  //     bsbNumber: '123-456',
  //     accountNumber: '987654321',
  //     bankAccountName: 'Bank Account 1',
  //     registeredAddress: 'Address 1',
  //     tPlus0: 'Yes',
  //     sale: 'Sale1',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Tairan',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Massimo',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Run',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  //   {
  //     saleId: 'abc124',
  //     termsAndConditions: 'Accepted',
  //     kyc: 'No',
  //     srnVerified: 'Yes',
  //     saleDate: '01/12/2024',
  //     shareholderName: 'Yuvi',
  //     brokerage: 'Brokerage2',
  //     postcode: '3000',
  //     companyOrCode: 'Company',
  //     srn: 'I123123123124',
  //     units: 40000,
  //     contactName: 'Contact2',
  //     contactEmail: 'contact2@example.com',
  //     poa: 'No',
  //     settlementFromSale: 'Settlement2',
  //     trust: 'No',
  //     bsbNumber: '234-567',
  //     accountNumber: '123456789',
  //     bankAccountName: 'Bank Account 2',
  //     registeredAddress: 'Address 2',
  //     tPlus0: 'No',
  //     sale: 'Sale2',
  //   },
  // ];

  // const [tradesData, setTradesData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);

  const [tradesToBeMadeData, setTradesToBeMadeData] = useState<GetTradesToBeMade200ResponseInner[]>(
    [],
  );
  const [filteredData, setFilteredData] = useState<GetTradesToBeMade200ResponseInner[]>([]);

  const [displayedColumns, setDisplayedColumns] = useState({
    saleId: true,
    termsAndConditions: true,
    kyc: true,
    srnVerified: true,
    saleDate: true,
    shareholderName: true,
    brokerage: true,
    postcode: true,
    companyOrCode: true,
    srn: true,
    units: true,
    contactName: true,
    contactEmail: true,
    poa: true,
    settlementFromSale: true,
    trust: true,
    bsbNumber: true,
    accountNumber: true,
    bankAccountName: true,
    registeredAddress: true,
    tPlus0: true,
    sale: true,
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    const fetchTradesData = async () => {
      try {
        // const response = await tradesApiMock.tradesToBeMadeGet(); // mock
        const response = await tradesToBeMadeApi.getTradesToBeMade();
        setTradesToBeMadeData(response.data || []);
        setFilteredData(response.data || []);
      } catch (error) {
        console.error('Failed to fetch trades data:', error);
        setTradesToBeMadeData([]);
        setFilteredData([]);
      }
    };

    fetchTradesData();
  }, []);

  useEffect(() => {
    const savedColumns = localStorage.getItem('customisedDisplayedFields');
    if (savedColumns) {
      setDisplayedColumns(JSON.parse(savedColumns));
    }
  }, []);

  //for test
  // const fetchTradesData = async () => {
  //   try {
  //     const response = await tradesApi.tradesToBeMadeGet();
  //     console.log('API Response:', response.data);
  //     setTradesData(response.data);
  //     setFilteredData(response.data);
  //   } catch (error) {
  //     console.error('Failed to fetch trades data:', error);
  //   }
  // };

  const handleColumnChange = (column: string, isChecked: boolean) => {
    setDisplayedColumns((prev) => ({
      ...prev,
      [column]: isChecked,
    }));
  };

  const handleShowAll = () => {
    const allChecked = Object.keys(displayedColumns).reduce(
      (acc, key) => {
        acc[key as keyof typeof displayedColumns] = true;
        return acc;
      },
      {} as { [key in keyof typeof displayedColumns]: boolean },
    );
    setDisplayedColumns(allChecked);
  };

  const handleHideAll = () => {
    const allUnchecked = Object.keys(displayedColumns).reduce(
      (acc, key) => {
        acc[key as keyof typeof displayedColumns] = false;
        return acc;
      },
      {} as { [key in keyof typeof displayedColumns]: boolean },
    );
    setDisplayedColumns(allUnchecked);
  };

  const handleSaveDisplayedFields = (columns: { [key: string]: boolean }) => {
    localStorage.setItem('customisedDisplayedFields', JSON.stringify(columns));
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const applyDateFilter = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString('en-GB');

      const filtered = tradesToBeMadeData.filter((trade) => trade.saleDate === formattedDate);
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(tradesToBeMadeData);
      setCurrentPage(1);
    }
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredData(tradesToBeMadeData);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Box>
      <HStack alignItems="start">
        <CustomDatePicker
          onDateChange={handleDateChange}
          onApply={applyDateFilter}
          onClear={clearDateFilter}
        />

        <DisplayedField
          displayedColumns={displayedColumns}
          onColumnChange={handleColumnChange}
          onSave={handleSaveDisplayedFields}
          onShowAll={handleShowAll}
          onHideAll={handleHideAll}
        />
      </HStack>
      <Box padding="4">
        <TableContainer
          overflowX="auto"
          maxWidth="1400px"
          border="1px solid #e2e8f0"
          borderRadius="md"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                {displayedColumns.saleId && <Th>Sale ID</Th>}
                {displayedColumns.termsAndConditions && <Th>T&Cs</Th>}
                {displayedColumns.kyc && <Th>KYC?</Th>}
                {displayedColumns.srnVerified && <Th>SRN?</Th>}
                {displayedColumns.saleDate && <Th>Sale Date</Th>}
                {displayedColumns.shareholderName && <Th>Shareholder Name</Th>}
                {displayedColumns.brokerage && <Th>Brokerage</Th>}
                {displayedColumns.postcode && <Th>Postcode</Th>}
                {displayedColumns.companyOrCode && <Th>Company or Code</Th>}
                {displayedColumns.srn && <Th>SRN</Th>}
                {displayedColumns.units && <Th>Units</Th>}
                {displayedColumns.contactName && <Th>Contact Name</Th>}
                {displayedColumns.contactEmail && <Th>Contact Email</Th>}
                {displayedColumns.poa && <Th>PoA?</Th>}
                {displayedColumns.settlementFromSale && <Th>Settlement (from Sale)</Th>}
                {displayedColumns.trust && <Th>Trust?</Th>}
                {displayedColumns.bsbNumber && <Th>BSB Num.</Th>}
                {displayedColumns.accountNumber && <Th>Acc. Number</Th>}
                {displayedColumns.bankAccountName && <Th>Bank Account Name</Th>}
                {displayedColumns.registeredAddress && <Th>Reg. Address</Th>}
                {displayedColumns.tPlus0 && <Th>T+0?</Th>}
                {displayedColumns.sale && <Th>Sale</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((trade, index) => (
                <Tr key={index}>
                  {displayedColumns.saleId && <Td>{trade.saleId}</Td>}
                  {displayedColumns.termsAndConditions && <Td>{trade.termsAndConditions}</Td>}
                  {displayedColumns.kyc && <Td>{trade.kyc}</Td>}
                  {displayedColumns.srnVerified && <Td>{trade.srnVerified}</Td>}
                  {displayedColumns.saleDate && <Td>{trade.saleDate}</Td>}
                  {displayedColumns.shareholderName && <Td>{trade.shareholderName}</Td>}
                  {displayedColumns.brokerage && <Td>{trade.brokerage}</Td>}
                  {displayedColumns.postcode && <Td>{trade.postcode}</Td>}
                  {displayedColumns.companyOrCode && <Td>{trade.companyOrCode}</Td>}
                  {displayedColumns.srn && <Td>{trade.srn}</Td>}
                  {displayedColumns.units && <Td>{trade.units}</Td>}
                  {displayedColumns.contactName && <Td>{trade.contactName}</Td>}
                  {displayedColumns.contactEmail && <Td>{trade.contactEmail}</Td>}
                  {displayedColumns.poa && <Td>{trade.poa}</Td>}
                  {displayedColumns.settlementFromSale && <Td>{trade.settlementFromSale}</Td>}
                  {displayedColumns.trust && <Td>{trade.trust}</Td>}
                  {displayedColumns.bsbNumber && <Td>{trade.bsbNumber}</Td>}
                  {displayedColumns.accountNumber && <Td>{trade.accountNumber}</Td>}
                  {displayedColumns.bankAccountName && <Td>{trade.bankAccountName}</Td>}
                  {displayedColumns.registeredAddress && <Td>{trade.registeredAddress}</Td>}
                  {displayedColumns.tPlus0 && <Td>{trade.tPlus0}</Td>}
                  {displayedColumns.sale && <Td>{trade.sale}</Td>}
                </Tr>
              ))}
              {Array.from({ length: pageSize - paginatedData.length }).map((_, index) => (
                <Tr key={`empty-${index}`}>
                  {Object.keys(displayedColumns).map(
                    (key) =>
                      displayedColumns[key as keyof typeof displayedColumns] && (
                        <Td key={`empty-${index}-${key}`}>&nbsp;</Td>
                      ),
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </Box>
  );
};

export default TradesToBeMade;
