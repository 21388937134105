import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, HStack } from '@chakra-ui/react';
import Pagination from '../../../Common/Pagination';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import FilesImport from '../FilesImport';
import { tradesMadeApi } from '../../../../api/api';
import { GetTradesMade200ResponseInner } from '../../../../api/trades-api/generated';
import RenderEmptyRows from '../RenderEmptyRows';

const TradesMade = () => {
  const [tradesMadeData, setTradesMadeData] = useState<GetTradesMade200ResponseInner[]>([]);
  const [filteredData, setFilteredData] = useState<GetTradesMade200ResponseInner[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const columns = [
    'iosName',
    'accountCode',
    'securityCode',
    'buySell',
    'volume',
    'price',
    'priceDollar',
    'value',
    'saleDate',
  ];

  useEffect(() => {
    const fetchTradesData = async () => {
      try {
        const response = await tradesMadeApi.getTradesMade();
        setTradesMadeData(response.data || []);
        setFilteredData(response.data || []);
      } catch (error) {
        console.error('Failed to fetch trades made data:', error);
        setTradesMadeData([]);
        setFilteredData([]);
      }
    };

    fetchTradesData();
  }, []);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const applyDateFilter = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString('en-GB');

      const filtered = tradesMadeData.filter((trade) => trade.saleDate === formattedDate);
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(tradesMadeData);
      setCurrentPage(1);
    }
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredData(tradesMadeData);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Box>
      <HStack alignItems="start" spacing={4}>
        <CustomDatePicker
          onDateChange={handleDateChange}
          onApply={applyDateFilter}
          onClear={clearDateFilter}
        />
        <FilesImport />
      </HStack>
      <Box marginY={4}>
        <TableContainer
          overflowX="auto"
          maxWidth="1400px"
          border="1px solid #e2e8f0"
          borderRadius="md"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>IOS Name</Th>
                <Th>Account Code</Th>
                <Th>Security Code</Th>
                <Th>Buy/Sell</Th>
                <Th>Volume</Th>
                <Th>Price</Th>
                <Th>Price $$</Th>
                <Th>Value</Th>
                <Th>Sale Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.iosName}</Td>
                  <Td>{item.accountCode}</Td>
                  <Td>{item.securityCode}</Td>
                  <Td>{item.buySell}</Td>
                  <Td>{item.volume}</Td>
                  <Td>{item.price}</Td>
                  <Td>{item.priceDollar}</Td>
                  <Td>{item.value}</Td>
                  <Td>{item.saleDate}</Td>
                </Tr>
              ))}
              <RenderEmptyRows data={paginatedData} columns={columns} pageSize={pageSize} />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </Box>
  );
};

export default TradesMade;
