import { Td, Tr } from '@chakra-ui/react';
import React from 'react';

interface RenderEmptyRowsProps {
  data: any[];
  columns: string[];
  pageSize: number;
}

const RenderEmptyRows = ({ data, columns, pageSize }: RenderEmptyRowsProps) => {
  const emptyRowsCount = pageSize - data.length;

  return (
    <>
      {/* {data.map((item, rowIndex) => (
        <Tr key={rowIndex}>
          {columns.map((column, colIndex) => (
            <Td key={`${rowIndex}-${colIndex}`}>{item[column] || '-'}</Td>
          ))}
        </Tr>
      ))} */}
      {Array.from({ length: emptyRowsCount }).map((_, emptyIndex) => (
        <Tr key={`empty-${emptyIndex}`}>
          {columns.map((_, colIndex) => (
            <Td key={`empty-${emptyIndex}-${colIndex}`}>&nbsp;</Td>
          ))}
        </Tr>
      ))}
    </>
  );
};

export default RenderEmptyRows;
