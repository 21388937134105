import React, { useEffect, useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, HStack } from '@chakra-ui/react';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import Pagination from '../../../Common/Pagination';
import FilesImport from '../FilesImport';
import { chessHoldingApi } from '../../../../api/api';
import { GetChessHolding200ResponseInner } from '../../../../api/trades-api/generated';
import RenderEmptyRows from '../RenderEmptyRows';

const ChessHolding = () => {
  const [chessHoldingDate, setChessHoldingDate] = useState<GetChessHolding200ResponseInner[]>([]);
  const [filteredData, setFilteredData] = useState<GetChessHolding200ResponseInner[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const columns = ['security', 'company', 'description', 'units', 'chessAvailable', 'date'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await chessHoldingApi.getChessHolding();
        setChessHoldingDate(response.data || []);
        setFilteredData(response.data || []);
      } catch (error) {
        console.error('Failed to fetch Chess Holding data:', error);
        setChessHoldingDate([]);
        setFilteredData([]);
      }
    };

    fetchData();
  }, []);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const applyDateFilter = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString('en-GB');
      const filtered = chessHoldingDate.filter((item) => item.date === formattedDate);
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(chessHoldingDate);
      setCurrentPage(1);
    }
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredData(chessHoldingDate);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Box>
      <HStack alignItems="start" spacing={4}>
        <CustomDatePicker
          onDateChange={handleDateChange}
          onApply={applyDateFilter}
          onClear={clearDateFilter}
        />
        <FilesImport />
      </HStack>
      <Box marginY={4}>
        <TableContainer
          overflowX="auto"
          maxWidth="1400px"
          border="1px solid #e2e8f0"
          borderRadius="md"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Security</Th>
                <Th>Company</Th>
                <Th>Description</Th>
                <Th>Units</Th>
                <Th>CHESS Available</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.security}</Td>
                  <Td>{item.company}</Td>
                  <Td>{item.description}</Td>
                  <Td>{item.units}</Td>
                  <Td>{item.chessAvailable}</Td>
                  <Td>{item.date}</Td>
                </Tr>
              ))}
              <RenderEmptyRows data={paginatedData} columns={columns} pageSize={pageSize} />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </Box>
  );
};

export default ChessHolding;
