/* tslint:disable */
/* eslint-disable */
/**
 * Trades API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface GetChessHolding200ResponseInner
 */
export interface GetChessHolding200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetChessHolding200ResponseInner
     */
    'security'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChessHolding200ResponseInner
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChessHolding200ResponseInner
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetChessHolding200ResponseInner
     */
    'units'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChessHolding200ResponseInner
     */
    'chessAvailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChessHolding200ResponseInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface GetDailyData200ResponseInner
 */
export interface GetDailyData200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'share'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDailyData200ResponseInner
     */
    'totalShares'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'chessHolding'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'airtableError'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'dtrError'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDailyData200ResponseInner
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface GetTradesMade200ResponseInner
 */
export interface GetTradesMade200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetTradesMade200ResponseInner
     */
    'iosName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesMade200ResponseInner
     */
    'accountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesMade200ResponseInner
     */
    'securityCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesMade200ResponseInner
     */
    'buySell'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTradesMade200ResponseInner
     */
    'volume'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradesMade200ResponseInner
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradesMade200ResponseInner
     */
    'priceDollar'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradesMade200ResponseInner
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTradesMade200ResponseInner
     */
    'saleDate'?: string;
}
/**
 * 
 * @export
 * @interface GetTradesToBeMade200ResponseInner
 */
export interface GetTradesToBeMade200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'saleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'termsAndConditions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'kyc'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'srnVerified'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'saleDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'shareholderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'brokerage'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'postcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'companyOrCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'srn'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'units'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'poa'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'settlementFromSale'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'trust'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'bsbNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'bankAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'registeredAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'tPlus0'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradesToBeMade200ResponseInner
     */
    'sale'?: string;
}

/**
 * ChessHoldingApi - axios parameter creator
 * @export
 */
export const ChessHoldingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all Chess Holding data
         * @summary Get Chess Holding data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChessHolding: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chess-holding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChessHoldingApi - functional programming interface
 * @export
 */
export const ChessHoldingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChessHoldingApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all Chess Holding data
         * @summary Get Chess Holding data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChessHolding(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChessHolding200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChessHolding(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChessHoldingApi.getChessHolding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChessHoldingApi - factory interface
 * @export
 */
export const ChessHoldingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChessHoldingApiFp(configuration)
    return {
        /**
         * Fetch all Chess Holding data
         * @summary Get Chess Holding data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChessHolding(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetChessHolding200ResponseInner>> {
            return localVarFp.getChessHolding(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChessHoldingApi - object-oriented interface
 * @export
 * @class ChessHoldingApi
 * @extends {BaseAPI}
 */
export class ChessHoldingApi extends BaseAPI {
    /**
     * Fetch all Chess Holding data
     * @summary Get Chess Holding data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChessHoldingApi
     */
    public getChessHolding(options?: RawAxiosRequestConfig) {
        return ChessHoldingApiFp(this.configuration).getChessHolding(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DailyDataApi - axios parameter creator
 * @export
 */
export const DailyDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all Daily Data
         * @summary Get Daily Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/daily-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyDataApi - functional programming interface
 * @export
 */
export const DailyDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all Daily Data
         * @summary Get Daily Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDailyData200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DailyDataApi.getDailyData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DailyDataApi - factory interface
 * @export
 */
export const DailyDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyDataApiFp(configuration)
    return {
        /**
         * Fetch all Daily Data
         * @summary Get Daily Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyData(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDailyData200ResponseInner>> {
            return localVarFp.getDailyData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DailyDataApi - object-oriented interface
 * @export
 * @class DailyDataApi
 * @extends {BaseAPI}
 */
export class DailyDataApi extends BaseAPI {
    /**
     * Fetch all Daily Data
     * @summary Get Daily Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyDataApi
     */
    public getDailyData(options?: RawAxiosRequestConfig) {
        return DailyDataApiFp(this.configuration).getDailyData(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TradesMadeApi - axios parameter creator
 * @export
 */
export const TradesMadeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all Trades Made data
         * @summary Get Trades Made data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradesMade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades-made`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradesMadeApi - functional programming interface
 * @export
 */
export const TradesMadeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradesMadeApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all Trades Made data
         * @summary Get Trades Made data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradesMade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTradesMade200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradesMade(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TradesMadeApi.getTradesMade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TradesMadeApi - factory interface
 * @export
 */
export const TradesMadeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradesMadeApiFp(configuration)
    return {
        /**
         * Fetch all Trades Made data
         * @summary Get Trades Made data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradesMade(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetTradesMade200ResponseInner>> {
            return localVarFp.getTradesMade(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradesMadeApi - object-oriented interface
 * @export
 * @class TradesMadeApi
 * @extends {BaseAPI}
 */
export class TradesMadeApi extends BaseAPI {
    /**
     * Fetch all Trades Made data
     * @summary Get Trades Made data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradesMadeApi
     */
    public getTradesMade(options?: RawAxiosRequestConfig) {
        return TradesMadeApiFp(this.configuration).getTradesMade(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TradesToBeMadeApi - axios parameter creator
 * @export
 */
export const TradesToBeMadeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all trades to be made
         * @summary Get trades to be made
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradesToBeMade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trades-to-be-made`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradesToBeMadeApi - functional programming interface
 * @export
 */
export const TradesToBeMadeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradesToBeMadeApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all trades to be made
         * @summary Get trades to be made
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradesToBeMade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTradesToBeMade200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradesToBeMade(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TradesToBeMadeApi.getTradesToBeMade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TradesToBeMadeApi - factory interface
 * @export
 */
export const TradesToBeMadeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradesToBeMadeApiFp(configuration)
    return {
        /**
         * Fetch all trades to be made
         * @summary Get trades to be made
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradesToBeMade(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetTradesToBeMade200ResponseInner>> {
            return localVarFp.getTradesToBeMade(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradesToBeMadeApi - object-oriented interface
 * @export
 * @class TradesToBeMadeApi
 * @extends {BaseAPI}
 */
export class TradesToBeMadeApi extends BaseAPI {
    /**
     * Fetch all trades to be made
     * @summary Get trades to be made
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradesToBeMadeApi
     */
    public getTradesToBeMade(options?: RawAxiosRequestConfig) {
        return TradesToBeMadeApiFp(this.configuration).getTradesToBeMade(options).then((request) => request(this.axios, this.basePath));
    }
}



