import React, { useEffect, useState } from 'react';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr, HStack } from '@chakra-ui/react';
import Pagination from '../../../Common/Pagination';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import { dailyDataApi } from '../../../../api/api';
import { GetDailyData200ResponseInner } from '../../../../api/trades-api/generated';
import RenderEmptyRows from '../RenderEmptyRows';

const DailyData = () => {
  // const dailyData = [
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  //   {
  //     share: 'ABC',
  //     totalShares: 50000,
  //     chessHolding: 'Yes',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '06/12/2024',
  //     time: '12:34:56',
  //   },
  //   {
  //     share: 'DEF',
  //     totalShares: 40000,
  //     chessHolding: 'No',
  //     airtableError: 'None',
  //     dtrError: 'None',
  //     date: '07/12/2024',
  //     time: '13:14:56',
  //   },
  // ];

  const [dailyData, setDailyData] = useState<GetDailyData200ResponseInner[]>([]);
  const [filteredData, setFilteredData] = useState<GetDailyData200ResponseInner[]>([]);

  // const [filteredData, setFilteredData] = useState(dailyData);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const columns = [
    'share',
    'totalShares',
    'chessHolding',
    'airtableError',
    'dtrError',
    'date',
    'time',
  ];

  useEffect(() => {
    const fetchDailyData = async () => {
      try {
        const response = await dailyDataApi.getDailyData();
        setDailyData(response.data || []);
        setFilteredData(response.data || []);
      } catch (error) {
        console.error('Failed to fetch daily data:', error);
        setDailyData([]);
        setFilteredData([]);
      }
    };

    fetchDailyData();
  }, []);

  const applyDateFilter = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString('en-GB');
      const filtered = dailyData.filter((data) => data.date === formattedDate);
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(dailyData);
      setCurrentPage(1);
    }
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredData(dailyData);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Box>
      <HStack marginBottom={4}>
        <CustomDatePicker
          onDateChange={setSelectedDate}
          onApply={applyDateFilter}
          onClear={clearDateFilter}
        />
      </HStack>
      <TableContainer
        marginBottom={4}
        overflowX="auto"
        border="1px solid #e2e8f0"
        borderRadius="md"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Share</Th>
              <Th>Total Shares</Th>
              <Th>CHESS HOLDING</Th>
              <Th>Airtable Error</Th>
              <Th>DTR Error</Th>
              <Th>Date</Th>
              <Th>Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData.map((data, index) => (
              <Tr key={index}>
                <Td>{data.share}</Td>
                <Td>{data.totalShares}</Td>
                <Td>{data.chessHolding}</Td>
                <Td>{data.airtableError}</Td>
                <Td>{data.dtrError}</Td>
                <Td>{data.date}</Td>
                <Td>{data.time}</Td>
              </Tr>
            ))}
            <RenderEmptyRows data={paginatedData} columns={columns} pageSize={pageSize} />
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </Box>
  );
};

export default DailyData;
