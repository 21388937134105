import { useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { LuMailOpen } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import FraudFlags from '../../components/FraudFlags';
import TabMenu from '../../components/TabMenu';
import ShareSales from '../../components/ShareSales';
import Trades from '../../components/Trades';
import SubTabMenu from '../../components/Trades/Components/SubTabMenu';

const Home = () => {
  const [activeTab, setActiveTab] = useState('fraudFlags');
  const [activeSubTab, setActiveSubTab] = useState<
    'tradesToBeMade' | 'dailyData' | 'tradesMade' | 'chessHolding'
  >('tradesToBeMade');

  const navigate = useNavigate();

  const username = localStorage.getItem('username') || 'Unknown';

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    navigate('/');
  };

  return (
    <Box textAlign={'left'}>
      <Flex direction="row" justifyContent="space-between">
        <Button backgroundColor="transparent">
          <LuMailOpen fontSize="36px" />
        </Button>

        <Flex direction="row" justifyContent="flex-end" alignItems="center" gap="24px">
          <Text fontSize="lg">User: {username}</Text>
          <Button colorScheme="teal" size="sm" onClick={handleSignOut}>
            Sign Out
          </Button>
        </Flex>
      </Flex>
      <Flex direction="row" width="1660px">
        <Box borderRight="1px solid #ccc">
          <TabMenu activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 'trades' && (
            <SubTabMenu activeSubTab={activeSubTab} setActiveSubTab={setActiveSubTab} />
          )}
        </Box>
        <Box flex="1" padding="4">
          {activeTab === 'fraudFlags' && <FraudFlags />}
          {activeTab === 'shareSales' && <ShareSales />}
          {activeTab === 'trades' && <Trades activeSubTab={activeSubTab} />}
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
