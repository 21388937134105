import axios from 'axios';
import { AuthApi, FraudFlagsApi, ShareSaleApi } from './sms-backoffice-api';
import { backendUrl } from './config/config';
import {
  Configuration as TradesApiConfig,
  TradesToBeMadeApi,
  ChessHoldingApi,
  DailyDataApi,
  TradesMadeApi,
} from './trades-api/generated';


const axiosInstance = axios.create({
  baseURL: backendUrl
  // baseURL: 'http://localhost:3001',
});

axiosInstance.interceptors.request.use(config => {

  // const fullUrl = `${config.baseURL}${config.url}`;
  // console.log("Sending Request to:", fullUrl, "With data:", config.data);

  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = token;  
  }
  return config;
}, error => {

  return Promise.reject(error);
});

//mock
// const axiosInstanceMock = axios.create({
//   baseURL: 'http://localhost:3001', 
// });

// axiosInstanceMock.interceptors.request.use(
//   (config) => config,
//   (error) => Promise.reject(error)
// );

export const authApi = new AuthApi(undefined, backendUrl, axiosInstance);
export const fraudFlagsApi = new FraudFlagsApi(undefined, backendUrl, axiosInstance);
export const shareSaleApi = new ShareSaleApi(undefined, backendUrl, axiosInstance);

const tradesApiConfig = new TradesApiConfig({ basePath: backendUrl });

export const tradesToBeMadeApi = new TradesToBeMadeApi(tradesApiConfig, tradesApiConfig.basePath, axiosInstance);
export const chessHoldingApi = new ChessHoldingApi(tradesApiConfig, tradesApiConfig.basePath, axiosInstance);
export const dailyDataApi = new DailyDataApi(tradesApiConfig, tradesApiConfig.basePath, axiosInstance);
export const tradesMadeApi = new TradesMadeApi(tradesApiConfig, tradesApiConfig.basePath, axiosInstance);

// const tradesApiMockConfig = new TradesApiConfig({ basePath: 'http://localhost:3001' });
// export const tradesApiMock = new TradesApi(tradesApiMockConfig, tradesApiMockConfig.basePath, axiosInstanceMock);